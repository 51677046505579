<template>
 <router-view></router-view>

</template>

<script>
import { provide, ref } from 'vue';

export default {
  name: 'App',
  components: {
  },
  setup() {
    const syncContact = ref(false);
    let db = ref(null);

    const getIDB = async () => {
      return new Promise((resolve, reject) => {
        let request = window.indexedDB.open("Contact", 1);

        request.onsuccess = e => {
          console.log("Success open db ", e);
          resolve(e.target.result);
        }

        request.onerror = e => {
          console.log("Error opening db ", e);
          reject(e);
        }

        request.onupgradeneeded = e => {
          console.log("onupgraded");
          db.value = e.target.result;
          db.value.createObjectStore("customer", {keyPath: 'ID', autoIncrement: true})
        }
      })
    }

    provide("sync_contact", syncContact);
    provide("db", db);

    return {
      updateDBValue: (val) => {
        db.value = val;
      },
      getIDB
    }
  },
  async mounted() {
    console.log("mounted App component");
    let idb = await this.getIDB();
    this.updateDBValue(idb);
  }
}
</script>
 