import io from "socket.io-client";
// import Swal from "sweetalert2";
import push from "push.js";
import axios from "axios";
import { inject } from "vue";
import dayjs from "dayjs";

export default {
  data() {
    return {
      socket: null,
      _notifData: [],
      ack: 0,
      chatData: null,
      newChat: false,
      logo: require("@/assets/favicon.png"),
      idb: inject("db")
    };
  },
  methods: {
    initSocket() {
      if (
        localStorage.getItem("user") !== null ||
        localStorage.getItem("user") !== "undefined"
      ) {
        var kodema = JSON.parse(localStorage.getItem("user"))?.Company
          .CompanyCode;

        this.socket = io(process.env.VUE_APP_WA_BASE_URL, {
          transports: ["websocket"],
          autoConnect: true,
          forceNew: true,
          extraHeaders: {
            "Access-Control-Allow-Origin": "*",
          },
          query: {
            Authorization: kodema,
            token: localStorage.getItem("jwt"),
          },
        });
        console.log("Socket refreshed");
        this._notifData = JSON.parse(localStorage.getItem("notif"));
      }
      if(!this.idb){
        this.idb = inject("db");
      }
    },
    async refreshContact(phone = 0) {
      console.log("refresh contact SocketMixins.js");
      localStorage.removeItem("Contact");
      var baseUrl = process.env.VUE_APP_URL_API;
      var addExtraParams =
        "&ClientID=" +
        process.env.VUE_APP_CLIENT_ID +
        "&AccessToken=" +
        localStorage.getItem("jwt");

      if(phone === 0){
        let userChat = await axios.get(
          baseUrl + "customer-api/getCustomerData?status=all" + addExtraParams
        );
  
        if (userChat.data.Data !== null) {
          let sortContact = userChat.data.Data.sort(
            (a, b) =>
              this.toTimeStamp(b.TimestampLastChat) -
              this.toTimeStamp(a.TimestampLastChat)
          );
          userChat.data.Data = sortContact;
          this.cleanDataDB();
          this.addCustomerToDB(userChat.data.Data)
        }
      } else {
        let userChat = await axios.get(`${process.env.VUE_APP_URL_API}customer-api/getCustomerByPhone`, {
          params: {
            ClientID: process.env.VUE_APP_CLIENT_ID,
            AccessToken: localStorage.getItem("jwt"),
            Phone: phone
          }
        })

        let responseData = null;
        if(userChat.status == 200){
          let response = userChat.data;
          if(response.Meta.Code == 200){
            responseData = response.Data;
          }
        }

        let oldContact = await this.getAllContact();
        if(responseData !== null){
          oldContact.push(responseData)
        }
        await this.cleanDataDB();
        await this.addCustomerToDB(oldContact);
      }      
    },
    async syncChatRoomsWa() {
      // let contactData = JSON.parse(localStorage.getItem("Contact"));
      let contactData = {
        data: {
          Data: await this.getAllContact()
        }
      };

      let chatDataLoad = [];
      let cacheChatData = null;
      let waGateway = null;

      let kodemb = JSON.parse(localStorage.getItem("user")).Company.CompanyCode
      let urlStateWa = process.env.VUE_APP_WA_BASE_URL + "api/whatsapp/status?kodemb=" + kodemb
      const statusWa = await axios.get(urlStateWa, {
        headers: {
          clientid: "abcd1234"
        }
      })

      let responseData = statusWa.data

      if(responseData.data !== 'WAITING'){
        for (let idx = 0; idx < contactData.data.Data.slice(0, 10).length; idx++) {
          const item = contactData.data.Data[idx];
  
          let Tag = [];
          if (item.Tags !== null) {
            item.Tags.forEach((element) => {
              Tag.push(element.Title);
            });
          }
  
          chatDataLoad.push({
            id: item.ID,
            image: item.Avatar === null ? this.profile : item.Avatar.Original,
            name: item.Name,
            telp: parseInt(item.Phone),
            message: cacheChatData ? cacheChatData.message : "",
            hasMedia: cacheChatData ? cacheChatData.hasMedia : "",
            ack: cacheChatData ? cacheChatData.ack : 0,
            Tags: Tag.join(","),
            timestamp: cacheChatData ? cacheChatData.timestamp : 0,
            unread_count: cacheChatData ? cacheChatData.unread_count : 0,
            fromMe: cacheChatData ? cacheChatData.fromMe : false,
            status: item.Status,
            firstTimeChat: item.CreatedTime,
            shimmer: cacheChatData ? cacheChatData.shimmer : true,
            type: cacheChatData ? cacheChatData.type : "chat",
          });
  
          waGateway = await axios.post(
            process.env.VUE_APP_WA_BASE_URL + "api/whatsapp/chat-rooms",
            {
              phones: [item.Phone],
              kodemb: JSON.parse(localStorage.getItem("user")).Company
                .CompanyCode,
            },
            {
              headers: {
                ClientID: "abcd1234",
              },
            }
          );
  
          let resWa;
          if (waGateway.data.status !== 500) {
            resWa = waGateway.data.data[0];
  
            if (resWa.last_message) {
              resWa.hasMedia = "deprecatedMms3Url" in resWa.last_message._data;
              if (resWa.hasMedia) {
                if (resWa.last_message._data.type !== "image") {
                  resWa.last_message.type = "image";
                  resWa.last_message.hasMedia = true;
                }
              }
  
              const regexItalic = /(_)([^_]+?)(_)/gs;
              const regexBold = /(\*)([^*]+?)(\*)/gs;
              const regexStrike = /(~)([^~]+?)(~)/gs;
  
              var body = resWa.last_message.body ?? "";
              body = body.replace(regexItalic, `<i>$2</i>`);
              body = body.replace(regexBold, `<b>$2</b>`);
              body = body.replace(regexStrike, `<strike>$2</strike>`);
  
              if (idx !== -1) {
                var isRevoked =
                  resWa.last_message._data.type === "revoked" ?? true;
                chatDataLoad[idx]["message"] = isRevoked
                  ? "<i>pesan ini dihapus</i>"
                  : body;
                chatDataLoad[idx]["hasMedia"] = resWa.last_message.hasMedia;
                chatDataLoad[idx]["ack"] = resWa.last_message.ack;
                chatDataLoad[idx]["timestamp"] = resWa.last_message.timestamp;
                chatDataLoad[idx]["unread_count"] = resWa.unread_count;
                chatDataLoad[idx]["fromMe"] = resWa.last_message.fromMe;
                chatDataLoad[idx]["type"] = resWa.last_message.type;
                if (resWa.photo !== null) {
                  chatDataLoad[idx]["image"] = resWa.photo;
                }
                chatDataLoad[idx]["shimmer"] = false;
              }
            }
          }
        }
        localStorage.setItem("ChatData", JSON.stringify(chatDataLoad));
      }
    },
    toTimeStamp(date) {
      return new Date(Date.parse(date)).getTime() / 1000;
    },
    /* 
     * function indexDB
     */
    async getAllContact(){
      return new Promise((resolve, reject) => {
        try {
          let trans = this.idb.transaction(['customer'], 'readonly');
          trans.oncomplete = () => {
            console.log("Success get all contact in mixins");
            resolve(customer);
          }
  
          trans.onerror = e => {
            console.log("Error in mixins", e);
            reject(e)
          }
  
          let store = trans.objectStore("customer");
          let objStoreReq = store.getAll();
          let customer = [];
  
          objStoreReq.onsuccess = () => {
            objStoreReq.result.sort((a, b) => dayjs(b.TimestampLastChat).unix() - dayjs(a.TimestampLastChat).unix());
            customer = objStoreReq.result
          }
  
          objStoreReq.onerror = () => {
            console.log("Error get customer");
          }
        } catch (error) {
          console.error("Error in mixins:",error.message);
        }
      })
    },
    async addCustomerToDB(cust){
      return new Promise((resolve, reject) => {
        try {
          let trans = this.idb.transaction(['customer'], 'readwrite')
  
          let store = trans.objectStore('customer')
          cust.forEach(item => {
            item.id = item.ID
            store.add(item)
          })

          trans.oncomplete = () => {
            console.log("Success add customer in mixins");
            resolve()
          }
  
          trans.onerror = e => {
            console.log("error", e);
            reject();
          }
        } catch (error) {
          console.error("Error in mixins:", error.message);
        }
      })
    },
    async cleanDataDB()
    {
      return new Promise((resolve, reject) => {
        let trans = this.idb.transaction(['customer'], 'readwrite')
        let objectStore = trans.objectStore("customer");

        let clearRequest = objectStore.clear();
        clearRequest.onsuccess = () => {
          console.log("All Data Success removed in mixins");
          resolve();
        }

        clearRequest.onerror = () => {
          console.log("All Data Fails remove in mixins");
          reject();
        }
      })
    },
    /* 
     * end
     */
  },
  watch: {
    socket(value) {
      console.log("Socket on mixion watch");
      var self = this;
      value.on("disconnect", () => {
        console.log("socket disconnected");
        self.socket.io.engine.close();
        self.initSocket();
      });

      value.on("chat", async function (data) {
        console.log("masuk chat mixins watch");
        const resChat = JSON.parse(data);

        // push into chatroom
        let from = resChat.from.split("@")[0];
        let localContact = JSON.stringify({
          data: {
            Data: await self.getAllContact()
          }
        });
        
        if (localContact) {
          let localContactJSON = JSON.parse(localContact);
          if(localContactJSON.data.Data !== null){
            let checkCustomer = localContactJSON.data.Data.findIndex(
              (el) => parseInt(el.Phone) === parseInt(from)
            );
            if (checkCustomer === -1) {
              await self.refreshContact(parseInt(from));
            }
          } else {
            await self.refreshContact();
          }
        } else {
          await self.refreshContact();
        }

        if (localStorage.getItem("ChatData")) {
          var body = resChat.body;
          body = body.replace(/(_)([^_]+?)(_)/gs, `<i>$2</i>`);
          body = body.replace(/(\*)([^*]+?)(\*)/gs, `<b>$2</b>`);
          body = body.replace(/(~)([^~]+?)(~)/gs, `<strike>$2</strike>`);

          let phoneNumber = resChat.from.split("@")[0];
          let idxReplace = JSON.parse(
            localStorage.getItem("ChatData")
          ).findIndex((el) => {
            return parseInt(el.telp) === parseInt(phoneNumber);
          });

          let dataReplaced = JSON.parse(localStorage.getItem("ChatData"));
          if(idxReplace !== -1){
            dataReplaced[idxReplace]["message"] = body;
            dataReplaced[idxReplace]["hasMedia"] = resChat.hasMedia;
            dataReplaced[idxReplace]["ack"] = resChat.ack;
            dataReplaced[idxReplace]["timestamp"] = resChat.timestamp;
            dataReplaced[idxReplace]["fromMe"] = resChat.fromMe;
            dataReplaced[idxReplace]["unread_count"] =
              parseInt(dataReplaced[idxReplace]["unread_count"]) + 1 ?? 0;
          }

          localStorage.removeItem("ChatData");
          localStorage.setItem("ChatData", JSON.stringify(dataReplaced));
        }

        if(Notification.permission == 'granted'){
          push.create(resChat._data.notifyName.toUpperCase(), {
            body: resChat.body,
            icon: "/favicon.png",
            timeout: 5000,
            silent: false,
            onClick: function () {
              window.focus();
              this.close();
            },
          });
        }

        // for set notification
        const notif = localStorage.getItem("notif");
        const resData = JSON.parse(data);

        if (notif !== null) {
          console.log("masuk notif ada 1");
          let notifData = JSON.parse(notif);
          var idxNotif = notifData.findIndex((el) => el.from === resData.from);
          if (idxNotif !== -1) {
            console.log("masuk notif ada 2");
            notifData[idxNotif] = resData;
          } else {
            console.log("buat notif baru");
            notifData.push(resData);
          }
          localStorage.setItem("notif", JSON.stringify(notifData));
        } else {
          console.log("masuk notif null");
          localStorage.setItem("notif", JSON.stringify([resData]));
        }
        this._notifData = JSON.parse(localStorage.getItem("notif"));
      });

      value.on("chat_create", async function(data) {
        console.log("chat create watch mixins");
        let dataEmit = JSON.parse(data);
        let phone = 0;
        if(!dataEmit.fromMe){
          phone = parseInt(dataEmit.from.split("@")[0]);
        } else {
          phone = parseInt(dataEmit.to.split("@")[0]);
        }

        let customerCheck = await axios.get(`${process.env.VUE_APP_URL_API}customer-api/getCustomerByPhone`, {
          params: {
            ClientID: process.env.VUE_APP_CLIENT_ID,
            AccessToken: localStorage.getItem("jwt"),
            Phone: phone
          }
        })

        const response = customerCheck.data;
        if(customerCheck.status == 200){
          if(response.Meta.Code == 200){
            const dataJson = response.Data;
            // let contactLocal = JSON.parse(localStorage.getItem("Contact")).data.Data;
            let contactLocal = await self.getAllContact();
            let idxContactUpdate = contactLocal.findIndex(el => {
              return parseInt(el.ID) === parseInt(dataJson.ID)
            })

            if(idxContactUpdate !== -1){
              contactLocal[idxContactUpdate] = dataJson
            }

            localStorage.setItem("Contact", JSON.stringify({
              data: {
                Data: contactLocal
              }
            }))
            self.cleanDataDB();
            self.addCustomerToDB(contactLocal);
          }
        }
      })
    },
  },
  /* async created() {
    this.initSocket();
  }, */
  /* beforeMount() {
    this.initSocket();
  }, */
};
